
const BUCKET_BASE_URL = `https://${process.env.REACT_APP_GENERAL_ASSETS_BUCKET}.s3.ca-central-1.amazonaws.com`;

const BUCKET_IMAGES_URL = `${BUCKET_BASE_URL}/images`;
const BUCKET_ICONS_URL = `${BUCKET_BASE_URL}/icons`;
const BUCKET_MEDIA_URL = `${BUCKET_BASE_URL}/media`;
const BUCKET_LOGO_URL = `${BUCKET_BASE_URL}/logo`;

export const iconAssets = {
  DownArrowIcon: `${BUCKET_ICONS_URL}/icon-arrow-down-dark.svg`,
  LeftArrowIcon: `${BUCKET_ICONS_URL}/icon-arrow-left-white.svg`,
  RightArrowIcon: `${BUCKET_ICONS_URL}/icon-arrow-right-white.svg`,
  UpArrowIcon: `${BUCKET_ICONS_URL}/icon-arrow-up-dark.svg`,
  ClapIcon: `${BUCKET_ICONS_URL}/icon-clap.png`,
  CrossIcon: `${BUCKET_ICONS_URL}/icon-cross.png`,
  FilterIcon: `${BUCKET_ICONS_URL}/icon-filter.png`,
  HeartIcon: `${BUCKET_ICONS_URL}/icon-heart.png`,
  LinkIcon: `${BUCKET_ICONS_URL}/icon-link.png`,
  NewsIcon: `${BUCKET_ICONS_URL}/icon-news.png`,
  NotificationsIcon: `${BUCKET_ICONS_URL}/icon-notifications.png`,
  PlusIcon: `${BUCKET_ICONS_URL}/icon-plus.png`,
  QuestionnaireIcon: `${BUCKET_ICONS_URL}/icon-questionnaire.png`,
  SearchIcon: `${BUCKET_ICONS_URL}/icon-search.png`,
  ResultsIcon: `${BUCKET_ICONS_URL}/icon-results.png`,
  UsersIcon: `${BUCKET_ICONS_URL}/icon-users.png`,
  LeafIcon: `${BUCKET_ICONS_URL}/leaf.png`,
  FiftyYrsIcon: `${BUCKET_ICONS_URL}/50yrs.png`,
  RatingIcon: `${BUCKET_ICONS_URL}/rating.png`,
  PlaneIcon: `${BUCKET_ICONS_URL}/plane.svg`,
  QualifyIcon: `${BUCKET_ICONS_URL}/qualify.png`,
  StarIcon: `${BUCKET_ICONS_URL}/star.svg`,
};

export const imageAssets = {
  FlyingPaperPlane: `${BUCKET_IMAGES_URL}/flying_paper_plane.png`,
  MobileSignupBanner: `${BUCKET_IMAGES_URL}/mobile_signup_banner.png`,
  PaymentBanner1: `${BUCKET_IMAGES_URL}/payment_bg1.png`,
  PaymentBanner2: `${BUCKET_IMAGES_URL}/payment_bg2.png`,
  PaymentBanner3: `${BUCKET_IMAGES_URL}/payment_bg3.png`,
  ResetPassword: `${BUCKET_IMAGES_URL}/reset_password.png`,
  SuccessCelebrate: `${BUCKET_IMAGES_URL}/success_celebrate.png`,
  WebLandingBanner: `${BUCKET_IMAGES_URL}/web_landing_world.png`,
  BookConsultBanner: `${BUCKET_IMAGES_URL}/book_consult.png`,
  MeetConsultBanner: `${BUCKET_IMAGES_URL}/meet_consult.png`,
  WantMoreOptions: `${BUCKET_IMAGES_URL}/want_more_options.png`,
};

export const mediaAssets = {
  Rocket: `${BUCKET_MEDIA_URL}/rocket.mp4`,
};

export const logoAssets = {
  LogoWhite: `${BUCKET_LOGO_URL}/logo_white.png`,
  Logo: `${BUCKET_LOGO_URL}/logo.png`,
};
