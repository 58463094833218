import React, { useState } from "react";
import ReactDOMServer from 'react-dom/server';
import ReactModal from "react-modal";
import { useDispatch, useSelector } from 'react-redux';

import { BsX } from "react-icons/bs";

import { isModalActive, closeModal, openModal } from "../../store/slices/modal";
import { setSubscription } from "../../store/slices/global";

import useCancelSubscription from "../../hooks/subscriptions/useCancelSubscription";

const modalName = "PACKAGES_CANCEL_SUBSCRIPTION_MODAL";
const modalTitle = "Cancel Subscription";
const modalClass = "cancel_subscription";

const topupLvl = 0;

ReactModal.setAppElement('#root');
const CancelSubscriptionModal = () => {
    const dispatch = useDispatch();
    const isModalVisible = useSelector(isModalActive(modalName));

    const [selectedOption, setSelectedOption] = useState('');
    const [note, setNote] = useState('');

    const { mutate: cancelSubscription } = useCancelSubscription();

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const onCloseModal = () => {
        setNote('')
        setSelectedOption('')
        dispatch(closeModal(modalName));
    };

    const onMakeCancel = (e) => {
        e.preventDefault();
        if (!selectedOption || note?.trim()?.length < 150) {
            return;
        }
        cancelSubscription({
            payload: { note },
            successCallback: async (subscription) => {
                if (subscription) {
                    dispatch(setSubscription(null));

                    dispatch(openModal({
                        name: 'MESSAGE_MODAL',
                        data: {
                            closeOnEsc: false,
                            closeOnOutsideClick: false,
                            content: ReactDOMServer.renderToString(
                                <div className="imm__cancel__success_box">
                                    <h1 className="mb-3">We're sad to see you go</h1>
                                    <h5>But remember, our door is always open for any consultations or support you need.</h5>
                                </div>
                            ),
                            buttons: [
                                { label: 'Continue', style: 'primary', className: "btn_block imm__regular__btn imm__btn__primary", onClick: () => dispatch(closeModal('MESSAGE_MODAL')) },
                            ],
                        },
                    }));
                    onCloseModal();
                }
            },
            errorCallback: (err) => {
                alert('Error cancel subscription.');
            }
        });
    }

    return (
        <ReactModal
            id={modalName}
            isOpen={isModalVisible}
            onRequestClose={onCloseModal}
            contentLabel={modalTitle}
            overlayClassName={`imm__modal_overlay ${topupLvl ? `imm__modal_overlay__topup${topupLvl}` : ''} imm__${modalClass}__modal_overlay`}
            className={`imm__modal_dialog ${topupLvl ? `imm__modal_dialog__topup${topupLvl}` : ''} imm__${modalClass}__modal_dialog`}
            shouldCloseOnEsc={true}
            shouldCloseOnOverlayClick={true}
        >
            <div className={`imm__modal_content imm__${modalClass}__modal_content`}>
                <div className={`imm__${modalClass}_inner_wrap`}>
                    <button className="close_btn" onClick={onCloseModal}>
                        <BsX />
                    </button>

                    <div className="imm__cancel__page_view">
                        <h3>Cancel Subscription</h3>
                        <br />
                        <h5 className="mb-2">How likely would you be to refer us?</h5>
                        <div className="imm__cancel__page_view_radio_groups">
                            <label>
                                <input
                                    type="radio"
                                    value="veryUnlikely"
                                    checked={selectedOption === 'veryUnlikely'}
                                    onChange={handleOptionChange}
                                />
                                Very unlikely
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="somewhatUnlikely"
                                    checked={selectedOption === 'somewhatUnlikely'}
                                    onChange={handleOptionChange}
                                />
                                Somewhat unlikely
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="neutral"
                                    checked={selectedOption === 'neutral'}
                                    onChange={handleOptionChange}
                                />
                                Neutral
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="somewhatLikely"
                                    checked={selectedOption === 'somewhatLikely'}
                                    onChange={handleOptionChange}
                                />
                                Somewhat likely
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="veryLikely"
                                    checked={selectedOption === 'veryLikely'}
                                    onChange={handleOptionChange}
                                />
                                Very likely
                            </label>
                        </div>
                        {(selectedOption === 'veryUnlikely' || selectedOption === 'somewhatUnlikely') ? <h5>We're all about getting better! Tell us why you're leaving and feel free to share the details.</h5> : <h5>What made you decide to leave? Please, feel free to share details.</h5>}
                        <textarea className="mt-2" rows={6} value={note} onChange={(e) => setNote(e.target.value)} />
                        <p className="small">(Minimum 150 characters)</p>
                        <button className={`btn_block imm__regular__btn imm__btn__primary ${!selectedOption || note?.trim()?.length < 150 ? "imm__btn__primary__disabled" : ""}`} onClick={onMakeCancel}>Submit</button>
                    </div>

                </div>
            </div>
        </ReactModal>
    );
};

export default CancelSubscriptionModal;