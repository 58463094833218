import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import { useDispatch, useSelector } from 'react-redux';

import { BsCheckLg, BsExclamationCircle, BsX } from "react-icons/bs";
import { BiSolidRightArrow, BiSolidDownArrow } from "react-icons/bi";

import { isModalActive, closeModal, openModal } from "../../store/slices/modal";
import { getPaymentRedirectUrl, getUserSubscription } from "../../store/slices/global";

import useGetPackages from "../../hooks/subscriptions/useGetPackages";
import useCreatePaymentIntent from "../../hooks/subscriptions/useCreatePaymentIntent";

import { iconAssets } from "../../constants/assets_url";

import { triggerGa4Event } from "../../helpers/tracking";

const modalName = "PACKAGES_MODAL";
const modalTitle = "Packages";
const modalClass = "packages";

const topupLvl = 0;

ReactModal.setAppElement('#root');
const PackagesModal = () => {

    const dispatch = useDispatch();
    const isModalVisible = useSelector(isModalActive(modalName));
    const paymentRedirectUrl = useSelector(getPaymentRedirectUrl());
    const userSubscription = useSelector(getUserSubscription());

    const [expandedSections, setExpandedSections] = useState([]);
    const [fetchPackages, setFetchPackages] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [isProcessRunning, setIsProcessRunning] = useState(false);

    const { data: packages } = useGetPackages(fetchPackages);
    const { mutate: createPaymentIntent } = useCreatePaymentIntent();

    const resetModalStates = () => {
        setExpandedSections([]);
        setSelectedPackage(null);
        setFetchPackages(false);
        setIsProcessRunning(false);
    };

    const afterOpenModal = () => {
        if (!fetchPackages && isModalVisible) {
            setFetchPackages(true);
        } else {
            setFetchPackages(false);
        }
    };

    const onCloseModal = () => {
        resetModalStates();
        dispatch(closeModal(modalName));
    };

    useEffect(() => {
        if (packages?.length) {
            const isAvailablePackage = packages.find(item => item.subscription_months > userSubscription?.package?.subscription_months)
            if (isAvailablePackage || !userSubscription) {
                setSelectedPackage(packages[packages.length - 1]);
            }
        }

        // eslint-disable-next-line
    }, [packages, isModalVisible]);

    const toggleSection = (section) => {
        if (expandedSections.includes(section)) {
            setExpandedSections(expandedSections.filter((item) => item !== section));
        } else {
            setExpandedSections([...expandedSections, section]);
        }
    };

    useEffect(() => {
        if (paymentRedirectUrl?.path) {
            if (paymentRedirectUrl.path === 'results') {
                if (!expandedSections.includes(1)) {
                    setExpandedSections([...expandedSections, 1]);
                }
            }

            if (paymentRedirectUrl.path === 'questionnaire/disclaimer') {
                if (!expandedSections.includes(2)) {
                    setExpandedSections([...expandedSections, 2]);
                }
            }

            if (paymentRedirectUrl.path === 'notifications') {
                if (!expandedSections.includes(3)) {
                    setExpandedSections([...expandedSections, 3]);
                }
            }
        }

        // eslint-disable-next-line
    }, [paymentRedirectUrl]);

    const onMakePayment = (e) => {
        e.preventDefault();
        if (selectedPackage) {

            triggerGa4Event({
                name: 'subscription_chosen',
                category: 'Subscription',
                action: 'click',
                label: 'User selected a package',
                value: {
                    amount: selectedPackage.amount,
                    type: selectedPackage.subscription_months > 1 ? 'annual' : 'monthly',
                }
            });

            setIsProcessRunning(true);

            createPaymentIntent({
                payload: {
                    currency: 'cad',
                    payment_method_type: 'card',
                    amount: Number(selectedPackage.amount),
                },
                successCallback: async (paymentIntent) => {
                    if (paymentIntent) {
                        triggerGa4Event({
                            name: 'begin_checkout',
                            category: 'Subscription',
                            action: 'click',
                            label: 'User started checkout process',
                            value: {
                                amount: selectedPackage.amount,
                                type: selectedPackage.subscription_months > 1 ? 'annual' : 'monthly',
                            }
                        });

                        dispatch(openModal({
                            name: 'PAYMENT_MODAL',
                            data: {
                                package: selectedPackage,
                                paymentOptions: {
                                    paymentIntentId: paymentIntent.id,
                                    clientSecret: paymentIntent.client_secret,
                                    appearance: {
                                        theme: 'stripe'
                                    }
                                },
                            }
                        }));
                        onCloseModal();
                    } else {
                        console.error('Error creating stripe session');
                        setIsProcessRunning(false);
                    }
                },
                errorCallback: (err) => {
                    console.error('Error creating stripe session');
                    setIsProcessRunning(false);
                }
            });
        };
    }

    return (
        <ReactModal
            id={modalName}
            isOpen={isModalVisible}
            onAfterOpen={afterOpenModal}
            onRequestClose={onCloseModal}
            contentLabel={modalTitle}
            overlayClassName={`imm__modal_overlay ${topupLvl ? `imm__modal_overlay__topup${topupLvl}` : ''} imm__${modalClass}__modal_overlay`}
            className={`imm__modal_dialog ${topupLvl ? `imm__modal_dialog__topup${topupLvl}` : ''} imm__${modalClass}__modal_dialog`}
            shouldCloseOnEsc={true}
            shouldCloseOnOverlayClick={true}
        >
            <div className={`imm__modal_content imm__${modalClass}__modal_content`}>
                <div className="imm__packages_inner_wrap">
                    <button className="close_btn" onClick={onCloseModal}>
                        <BsX />
                    </button>

                    <h6 className="color_success">GO PREMIUM</h6>
                    <h2 className="text_center pt-2 pb-3">Increase your<br />chances to get PR!</h2>

                    <div className="imm__subscription_feature_list">
                        <div className="imm__sfl_container">
                            <div className="imm__sfl_left" />
                            <div className="imm__sfl_right color_primary">Free</div>
                            <div className="imm__sfl_right color_primary">Premium</div>
                        </div>

                        <div className="imm__sfl_container">
                            <div className="imm__sfl_left">
                                <div className="imm__sfl_left__top" onClick={() => toggleSection(0)}>
                                    {expandedSections.includes(0) ? <BiSolidDownArrow /> : <BiSolidRightArrow />}
                                    <span className={``}>Immigration Changes</span>
                                </div>
                                <div className={`imm__sfl_left__bottom ${expandedSections.includes(0) ? '' : 'd_none'}`}>
                                    Immigration changes frequently! Get full access to our patented technology that keeps you updated with tailored immigration changes just for you!
                                </div>
                            </div>
                            <div className="imm__sfl_right"><BsExclamationCircle className="color_error" /></div>
                            <div className="imm__sfl_right"><BsCheckLg className="color_success" /></div>
                        </div>

                        <div className="imm__sfl_container">
                            <div className="imm__sfl_left">
                                <div className="imm__sfl_left__top" onClick={() => toggleSection(1)}>
                                    {expandedSections.includes(1) ? <BiSolidDownArrow /> : <BiSolidRightArrow />}
                                    <span className={paymentRedirectUrl?.path === 'results' ? 'color_success' : ''}>Full Results</span>
                                </div>
                                <div className={`imm__sfl_left__bottom ${expandedSections.includes(1) ? '' : 'd_none'}`}>
                                    Get full access to all results and recommendations.
                                </div>
                            </div>
                            <div className="imm__sfl_right"><BsExclamationCircle className="color_error" /></div>
                            <div className="imm__sfl_right"><BsCheckLg className="color_success" /></div>
                        </div>

                        <div className="imm__sfl_container">
                            <div className="imm__sfl_left">
                                <div className="imm__sfl_left__top" onClick={() => toggleSection(2)}>
                                    {expandedSections.includes(2) ? <BiSolidDownArrow /> : <BiSolidRightArrow />}
                                    <span className={paymentRedirectUrl?.path === 'questionnaire/disclaimer' ? 'color_success' : ''}>Update Your Profile</span>
                                </div>
                                <div className={`imm__sfl_left__bottom ${expandedSections.includes(2) ? '' : 'd_none'}`}>
                                    As life changes, options change. Edit your profile at any time and check your results!
                                </div>
                            </div>
                            <div className="imm__sfl_right"><BsExclamationCircle className="color_error" /></div>
                            <div className="imm__sfl_right"><BsCheckLg className="color_success" /></div>
                        </div>

                        <div className="imm__sfl_container">
                            <div className="imm__sfl_left">
                                <div className="imm__sfl_left__top" onClick={() => toggleSection(3)}>
                                    {expandedSections.includes(3) ? <BiSolidDownArrow /> : <BiSolidRightArrow />}
                                    <span className={`${paymentRedirectUrl?.path === 'notifications' ? 'color_success' : ''}`}>Notifications</span>
                                </div>
                                <div className={`imm__sfl_left__bottom ${expandedSections.includes(3) ? '' : 'd_none'}`}>
                                    Our PREMIUM notifications will be tailored just for you and based on your profile! You don't want to miss out on what we have to share with you.
                                </div>
                            </div>
                            <div className="imm__sfl_right"><BsExclamationCircle className="color_error" /></div>
                            <div className="imm__sfl_right"><BsCheckLg className="color_success" /></div>
                        </div>

                        <div className="imm__sfl_container">
                            <div className="imm__sfl_left">
                                <div className="imm__sfl_left__top" onClick={() => toggleSection(4)}>
                                    {expandedSections.includes(4) ? <BiSolidDownArrow /> : <BiSolidRightArrow />}
                                    <span className={``}>Expert Consultation</span>
                                    <img src={iconAssets.StarIcon} alt="" />
                                </div>
                                <div className={`imm__sfl_left__bottom ${expandedSections.includes(4) ? '' : 'd_none'}`}>
                                    30-minute video consultation with a Toronto-based senior immigration expert.<br /><strong className="color_success">$150 value</strong>
                                </div>
                            </div>
                            <div className="imm__sfl_right"><BsExclamationCircle className="color_error" /></div>
                            <div className="imm__sfl_right">
                                {
                                    selectedPackage?.subscription_months >= 12
                                        ? <BsCheckLg className="color_success" />
                                        : <BsExclamationCircle className="color_error" />
                                }
                            </div>
                        </div>
                    </div>


                    <div className="imm__packages_list">
                        {(!!packages?.length) &&
                            packages?.map((item, index) => {
                                const selected = selectedPackage?.id === item.id;
                                const packageType = item.subscription_months > 1 ? 'YEARLY' : 'MONTHLY';
                                const packageShortType = item.subscription_months > 1 ? 'YR' : 'MO';
                                const discount = item.subscription_months >= 12 ? 30 : 0;
                                const disabled = userSubscription?.package?.subscription_months && item.subscription_months <= userSubscription?.package?.subscription_months ? true : false;

                                return (
                                    <div key={index} className={`imm__package_item ${disabled ? 'imm__package_item__disabled' : ''} ${selected ? 'imm__package_item__selected' : ''}`} onClick={() => !disabled && setSelectedPackage(item)}>
                                        <div className="package_title">{packageType}</div>
                                        <div className="package_amount">${item.amount} CAD/{packageShortType}</div>
                                        {discount > 0 && <div className="discount_cont">Best&nbsp;Value</div>}
                                        {/* {discount > 0 && <div className="original_price">${(parseFloat(item.amount) / ((100 - discount) / 100)).toFixed(2)}/{packageShortType}</div>} */}
                                        {!discount && <div className="package_mo_descr">Change or cancel anytime</div>}
                                        {discount > 0 &&
                                            <div className="package_yr_descr pt-4">
                                                <img src={iconAssets.StarIcon} alt="" />
                                                <span>Free consult</span>
                                            </div>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>

                    <p className="mice pt-3 pb-2">Money-back guarantee</p>
                    <button
                        className={`btn_block imm__regular__btn imm__btn__primary ${!selectedPackage ? "imm__btn__primary__disabled" : ""}`}
                        onClick={onMakePayment}
                        disabled={!selectedPackage || isProcessRunning}
                    >
                        {
                            isProcessRunning
                                ? <i className='imm__btn_loader' />
                                : `Start today`
                        }
                    </button>

                </div>
            </div>
        </ReactModal>
    );
};

export default PackagesModal;