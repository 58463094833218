import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import Hotjar from '@hotjar/browser';

export const initGA4 = () => {
    if (process.env.REACT_APP_APP_ENV === 'PRODUCTION') {
        ReactGA.initialize([
            { trackingId: process.env.REACT_APP_GA4_MEASUREMENT_ID },
        ]);
    };
};

export const initGTM = () => {
    if (process.env.REACT_APP_APP_ENV === 'PRODUCTION') {
        TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID });
    };
};

export const initHotjar = () => {
    if (process.env.REACT_APP_APP_ENV === 'PRODUCTION') {
        Hotjar.init(process.env.REACT_APP_HOTJAR_SITE_ID, process.env.REACT_APP_HOTJAR_VERSION);
    };
};

export const triggerGa4Event = ({ name, category, action, label, value }) => {
    if (process.env.REACT_APP_APP_ENV === 'PRODUCTION') {
        ReactGA.event(name, { category, action, label, value });
    };
};