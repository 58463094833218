import React, { Suspense, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import "bootstrap/dist/css/bootstrap.min.css";
import "../fontawesome-5/css/all.min.css";
import '../styles/index.scss';

import PageLoader from '../components/older/Components/Loader';

import MessageModal from './modals/Message';
import PackagesModal from './modals/Packages';
import PaymentModal from './modals/Payment';
import ReportBugModal from './modals/Report';
import CancelSubscriptionModal from './modals/CancelSubscription';

import { closeAllModals, openModal, closeModal } from '../store/slices/modal';

import cookiesHelper from '../helpers/cookies';
import authHelper from '../helpers/auth';

import useIdleTimeout from "../hooks/useIdleTimeout";
import useRefreshAuthToken from '../hooks/auth/useRefreshAuthToken';
import useSignOut from '../hooks/auth/useSignOut';

const App = (props) => {
    const { children } = props;

    const location = useLocation();
    const dispatch = useDispatch();

    const { mutate: signOut } = useSignOut();
    const { mutate: refreshAuthToken } = useRefreshAuthToken();

    useLayoutEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        dispatch(closeAllModals());

        // eslint-disable-next-line
    }, [location]);

    const staySigned = async () => {
        dispatch(closeModal('MESSAGE_MODAL'));

        refreshAuthToken({
            successCallback: (auth_token) => {
                cookiesHelper.setCookie(process.env.REACT_APP_AUTH_TOKEN_NAME, auth_token, 30 * 60 * 1000);
            },
            errorCallback: () => {
                signOut();
            }
        });
    };

    const onActionIp = () => {
        const tokenValidationRes = authHelper.isAuthTokenValid();
        if (!tokenValidationRes.result) {
            if (tokenValidationRes.reason !== 'not_found') signOut();
        } else {
            const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME);
            const tokenPayload = JSON.parse(atob(token.split('.')[1]));
            const tokenExp = tokenPayload.exp;
            const dateNow = new Date();

            if ((tokenExp - (dateNow.getTime() / 1000)) <= (4 * 60)) {
                staySigned(null);
            };
        }
    };

    const handleIdle = () => {
        if (authHelper.isAuthTokenValid().result) {
            return dispatch(openModal({
                name: 'MESSAGE_MODAL',
                data: {
                    icon: 'info',
                    title: 'Are you still there?',
                    titleColor: 'primary',
                    content: 'For security reasons, you will be logged out in 5 minutes if you do not respond.',
                    buttons: [
                        { label: 'Stay Signed In', style: 'primary', onClick: staySigned },
                        { label: 'Logout', style: 'info', onClick: signOut },
                    ]
                },
            }));
        };
    };

    // eslint-disable-next-line
    const { idleTimer } = useIdleTimeout({ onIdle: handleIdle, onActionIp: onActionIp, idleTime: (25 * 60) });

    return (
        <React.Fragment>
            <Suspense fallback={<PageLoader overlay="true" />}>

                <MessageModal />
                <PackagesModal />
                <PaymentModal />
                <ReportBugModal />
                <CancelSubscriptionModal />

                <div className="imm__immproved_app">
                    {children}
                </div>

            </Suspense>
        </React.Fragment>
    );
};

App.propTypes = {
    children: PropTypes.element.isRequired,
};

export default App;