import { Navigate, Outlet, useLocation } from "react-router-dom";

import authHelper from "../helpers/auth";

const PrivateRoutes = () => {
    const location = useLocation();

    const tokenValidationRes = authHelper.isAuthTokenValid();

    return (
        tokenValidationRes.result
            ? <Outlet />
            : tokenValidationRes.reason === 'not_found'
                ? <Navigate to='/auth' state={{ from: location }} replace />
                : <Navigate to='/auth' state={{ from: location }} replace />
        // : <Navigate to={`/redirect-login?reason=${tokenValidationRes.reason}_token`} replace />
    );
};

export default PrivateRoutes;